@import "../sass-vars";

.absences-list {
  display: flex;
  flex-direction: column;
  gap: var(--padding);
  .absences-list--absence {
    display: grid;
    grid-template-areas: "elapsed date" "elapsed description";
    grid-template-columns: 6rem auto;
    align-items: center;
    column-gap: var(--padding);
    color: var(--colour-content-text);
    background-color: var(--colour-absence-holiday);
    border-radius: var(--border-radius-inner);
    padding: 0.5rem;
    box-shadow: var(--shadow);
    .absences-list--absence--elapsed {
      grid-area: elapsed;
      .absence-elapsed-circle {
        border-radius: 50%;
        aspect-ratio: 1 / 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        line-height: 1.1;
        font-size: 2rem;
        font-weight: bold;
        background-color: rgba(255, 255, 255, 0.25);
        cursor: default;
        > div:last-child {
          font-size: 1rem;
          font-weight: normal;
        }
      }
    }
    .absences-list--absence--date {
      grid-area: date;
      font-size: 1.5rem;
    }
    .absences-list--absence--description {
      grid-area: description;
    }
  }
  .absences-list--absence[data-type="sickness"] {
    background-color: var(--colour-absence-sickness);
  }
  .absences-list--absence[data-type="other"] {
    background-color: var(--colour-absence-other);
  }
  .absences-list--absence[data-type="family"] {
    background-color: var(--colour-absence-family);
  }
}
.employee-absences {
  display: flex;
  gap: var(--padding);
  justify-content: space-evenly;
  align-items: flex-start;
  margin-bottom: calc(var(--padding) * 2);
  @media (max-width: $breakpoint-tablet) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: calc(var(--padding) + 1rem);
  }
  h3 {
    margin: 0;
    padding: 0;
  }
  .employee-absences--summary {
    // flex-grow: 1;
    max-width: 40rem;
    display: flex;
    flex-direction: column;
    gap: var(--padding);
    justify-content: flex-start;
    align-items: center;

    .employee-absences--summary-table {
      flex-grow: 1;
      width: 100%;
      max-width: 40rem;
      display: grid;
      gap: 1px;
      border: 1px solid var(--colour-borders);
      grid-template-columns: repeat(2, 1fr);
      color: var(--colour-content-text);
      background-color: var(--colour-borders);
      overflow: hidden;
      border-collapse: collapse;
      border-radius: var(--border-radius-inner);
      box-shadow: var(--shadow);
      > * {
        padding: var(--padding-tight);
        background-color: var(--colour-content-background);
      }
      .employee-absences--summary--label {
        font-weight: bold;
      }
    }
  }
  .employee-absences--values {
    max-width: 30rem;
    // margin: 0 auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: var(--padding);
    h3 {
      text-transform: capitalize;
    }
    .holiday-summary--allocation {
      padding: 0 var(--padding);
      grid-template-columns: repeat(4, 1fr);
      .holiday-summary--allocation-label {
        display: none;
      }
      .holiday-summary--allocation-item:nth-child(6) {
        grid-column: 2 / 4;
        grid-row: 2;
        padding-top: 1rem;
      }
      .holiday-summary--allocation-remaining-label {
        grid-column: 2 / 4;
        grid-row: 3;
        text-align: center;
      }
    }
    // .button {
    //   margin: 0 auto;
    // }
  }
}

.remaining-leave {
  background-color: var(--colour-absence-holiday);
  padding: var(--padding-tight);
  border-radius: var(--border-radius-inner);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: calc(2ch + var(--padding-tight) + var(--padding-tight));
  .lds-ellipsis div {
    filter: saturate(0);
  }
}

@media print {
  #print-container .absences-list-container {
    .absences-list {
      display: block;
      font-size: 0.8rem;
      columns: 3;
      background: transparent;
      padding: 0;
      margin: 0;
      height: fit-content;

      .absences-list--absence {
        box-shadow: none;
        background: transparent;
        break-inside: avoid;
        grid-template-columns: 5rem auto;
        row-gap: 0.1cm;
        .absence-elapsed-circle {
          background: transparent;
          font-size: 1.2rem;
          border: 2mm solid var(--colour-absence-holiday);
        }
        .absences-list--absence[data-type="sickness"] {
          .absence-elapsed-circle {
            border-color: var(--colour-absence-sickness);
          }
        }
        .absences-list--absence[data-type="other"] {
          .absence-elapsed-circle {
            border-color: var(--colour-absence-other);
          }
        }
        .absences-list--absence[data-type="family"] {
          .absence-elapsed-circle {
            border-color: var(--colour-absence-family);
          }
        }
        .absences-list--absence--date {
          font-size: 1.1rem;
          align-self: flex-end;
        }
        .absences-list--absence--description {
          align-self: flex-start;
        }
      }
    }
  }
}
