.file-library-links {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  gap: var(--padding);
  z-index: 20;
  button {
    flex-grow: 1;
    flex-basis: 0;
    border: 2px solid var(--colour-borders);
    padding: 1rem;
    border-radius: var(--border-radius-inner);
    cursor: pointer;
    display: flex;
    gap: 1rem;
    align-items: center;
    background-color: var(--colour-content-background);
    color: var(--colour-content-text);
    transition: border 0.2s ease-out;
    font: inherit;
    svg {
      height: 4rem;
    }
    div {
      text-align: center;
      flex-grow: 1;
    }
  }
  button:hover {
    filter: none;
    border: 2px solid var(--colour-buttons);
  }
  button[data-can-download="false"] {
    cursor: default;
  }
  button[data-can-download="false"]:hover {
    filter: none;
  }
}
