.drawer-overlay {
  content: "";
  width: 100vw;
  height: 100vh;
  z-index: 51;
  background-color: rgba(0, 0, 0, 0.7);
  transition: background-color 0.5s ease-out;
  position: fixed;
  top: 0;
  left: 0;
  animation: fade-in 0.5s forwards;
}
.drawer-overlay__closed {
  background-color: rgba(0, 0, 0, 0);
}
.drawer-container {
  animation: drawer-slide-in 0.2s var(--easing) forwards;
  width: 100%;
  max-width: 60rem;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 52;
}
.drawer {
  background-color: var(--colour-primary-faded);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: var(--colour-content-text);
  transform: translateX(0);
  transition: all 0.3s var(--easing);
  display: grid;
  grid-template-columns: 99fr 1fr;
  grid-template-rows: min-content auto min-content;
  grid-template-areas:
    "heading close"
    "content content"
    "footer footer";
  height: 100vh;
  align-items: center;
  h3 {
    margin: 0;
  }
  .drawer--heading {
    grid-area: heading;
    padding: var(--padding);
    color: var(--colour-primary-faded-text);
    margin: 0;
    * {
      margin: 0;
      color: var(--colour-primary-faded-text);
    }
    h2 {
      font-size: 2rem;
    }
  }
  .drawer--close {
    grid-area: close;
    width: min-content;
    padding: var(--padding);
    display: flex;
    gap: var(--padding);
    .button__arrow-back {
      min-width: fit-content;
      > * {
        min-width: auto;
      }
    }
  }
  .drawer--content {
    background-color: var(--colour-background);
    grid-area: content;
    overflow: auto;
    height: 100%;
    padding-left: var(--padding);
    padding-right: var(--padding);
    padding-top: var(--padding);
    padding-bottom: var(--padding);
    scroll-behavior: smooth;
    position: relative;
  }

  .drawer--content::before {
    content: "";
    position: fixed;
    z-index: 100;
    margin-top: calc(0px - var(--padding));
    left: 0;
    height: 0.8rem;
    width: 100%;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 100%);
  }
  .drawer--content__submitting {
    pointer-events: none;
    > * {
      opacity: 0.5;
    }
  }

  .drawer-action-buttons {
    .drawer-action-buttons-heading {
      flex-grow: 1;
      font-weight: normal;
      text-align: center;
      b {
        display: block;
        text-align: center;
      }
    }
    background-color: var(--colour-content-background);
    color: var(--colour-content-text);
    padding: 0.5rem var(--padding);
    border: 1px solid var(--colour-borders);
    border-radius: var(--border-radius-outer);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;
    gap: 1rem;
    overflow: visible;
  }

  .drawer-action-buttons[data-is-disabled="true"] {
    pointer-events: none;
    opacity: 0.8;
  }

  .drawer--footer-buttons {
    grid-area: footer;
    display: flex;
    justify-content: space-evenly;
    align-items: stretch;
    padding: var(--padding);
    height: min-content;
    background-color: var(--colour-content-background);
    position: relative;
    .button {
      font-size: 1.1rem;
      min-width: 10rem;
      justify-content: center;
      z-index: 1;
    }
  }
  .drawer--footer-buttons::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--colour-primary-faded-more);
  }
  .drawer-form--section {
    padding: var(--padding);
  }
  .richtext-container {
    border: 1px solid var(--colour-borders);
    border-radius: var(--border-radius-small);
    padding: var(--padding-tight);
  }
}
.drawer__closed {
  transform: translateX(100%) !important;
}

@keyframes drawer-slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
