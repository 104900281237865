.tags {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 0.125rem;
  max-width: 50%;
  justify-content: flex-start;
  cursor: pointer;
  z-index: 1;
  .tags--tag {
    flex-shrink: 1;
    white-space: nowrap;
    padding: 0.25rem;
    background-color: var(--colour-content-background);
    color: var(--colour-content-text);
    border: 1px solid var(--colour-borders);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 0.25rem;
    border-radius: var(--border-radius-small);
    transition: all 0.2s ease-out;
    font: inherit;
    font-size: 0.8rem;
    button {
      background: transparent;
      font: inherit;
      color: inherit;
      align-items: center;
      border: 0;
    }
    .tags--tag-button {
      padding: 0 0 0 0.25rem;
      flex-grow: 1;
      display: flex;
      gap: 0.25rem;
    }
    .tags--tag-delete {
      padding: 0;
      flex-shrink: 1;
      cursor: pointer;
      width: 16px;
      height: 16px;
      border-radius: 2px;
      transition: all 0.2s ease-out;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .tags--tag-delete:hover {
      background-color: var(--colour-rejected);
      color: #fff;
    }
  }
  .tags--tag-selection {
    cursor: default;
    width: min-content;
    top: calc(100% + 0.25rem);
    min-height: 5rem;
    background-color: var(--colour-content-background);
    border: 1px solid var(--colour-borders);
    border-radius: var(--border-radius-small);
    padding: 0.5rem;
    transform-origin: 0 0;
    transform: scaleY(1);
    animation: dropdown-overshoot 0.4s var(--easing) forwards;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 0.125rem;
    box-shadow: var(--shadow);
    transition: opacity 0.2s ease;
    .tags--tag {
      border-radius: var(--border-radius-small);
      background-color: var(--colour-primary-against-white-text);
      color: var(--colour-primary-against-white);
      transition: none;
      z-index: 1;
    }
    .tags--tag__selected {
      outline: 2px solid var(--colour-primary-against-white);
      z-index: 2;
    }
    .tags--tag:hover {
      background-color: var(--colour-primary-against-white);
      color: var(--colour-primary-against-white-text);
    }
    .tags--tag-input {
      font-size: 0.8rem;
      padding: 0.25rem;
      background-color: var(--colour-content-background);
      border: 1px solid var(--colour-borders);
      margin: 0.25rem 0;
    }
  }
  .tags--tag-selection__busy {
    pointer-events: none;
    * {
      opacity: 0.6;
    }
    .tags--tag-input {
      text-align: center;
    }
  }
  .tags--tag-selection:focus {
    outline: none;
  }
}
.tags__no-click {
  cursor: default;
}
.tags.pages-tags {
  margin-left: 0.5rem;
  position: relative;
  top: 0.1rem;
  .tags--tag-selection {
    position: absolute;
    z-index: 10;
  }
}
.tags:hover {
  > .tags--tag {
    filter: brightness(1.2);
  }
}
.tags__active {
  z-index: 2;
}
.tags__passive {
  cursor: default;
}
.tags.tags__passive:hover {
  > .tags--tag {
    filter: none;
  }
}
html.theme-dark {
  .tags {
    > .tags--tag {
      background-color: var(--colour-primary-against-black);
      color: var(--colour-primary-against-black-text);
    }
  }
}
.tag {
  border-radius: var(--border-radius-small);
  background-color: var(--colour-primary-against-white-text);
  color: var(--colour-primary-against-white);
  padding: 0.25rem;
  font-size: 0.8rem;
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  margin: 0 0.25rem;
  cursor: default;
}
.tag.tag__alert {
  background-color: var(--colour-rejected);
}
