.reset-password-form {
  display: flex;
  justify-content: space-between;
  gap: var(--padding);
  width: 100%;
  input {
    flex-grow: 1;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    justify-content: center;
  }
}
