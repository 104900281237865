.dev-info {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 400px;
  background-color: #000;
  outline: 2px solid #fff;
  height: min-content;
  padding: 1rem;
  color: #fff;
  z-index: 99999999999;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  cursor: pointer;
  .dev-info--item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    .dev-info--item--title {
      font-size: 1.2rem;
      font-weight: 700;
    }
  }

  .dev-info--item:first-child {
    color: yellow;
  }
  .dev-info--item:nth-child(2) {
    color: rgb(94, 255, 94);
  }
  .dev-info--item:nth-child(3) {
    color: rgb(255, 150, 150);
  }
  .dev-info--item:nth-child(4) {
    color: rgb(175, 226, 255);
  }
}
.dev-info[data-show="false"] {
  overflow: hidden;
  background: transparent;
  outline: 0;
  img {
    width: 3rem;
    height: auto;
    background: #fff;
    border-radius: 50%;
    padding: 2px;
  }
}
