.modal {
  overflow: auto;
  animation: fade-in 0.3s forwards;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  .modal--overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
  }
  .close-button {
    z-index: 3;
    position: fixed;
    top: var(--padding);
    right: var(--padding);
  }
  .print-button {
    z-index: 3;
    position: fixed;
    top: var(--padding);
    right: calc(var(--padding) + 60px);
  }
  .modal--content {
    z-index: 2;
    background-color: var(--colour-content-background);
    border: 1px solid var(--colour-borders);
    padding: calc(var(--padding) * 2);
    border-radius: var(--border-radius-outer);
    max-width: min(900px, 95vw);
    width: fit-content;
    min-width: 500px;
    transition: width 1s ease-in-out;
    position: relative;
    min-height: 300px;
    max-height: 90vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: var(--padding);
    > h2 {
      padding: 0;
      margin: 0;
    }
    h3 {
      padding: 0;
      margin: 0;
    }
    .modal--close {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      font-size: 20px;
      color: #000;
    }
    .close-button {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
    @media (max-width: 500px) {
      min-width: 0;
      width: 95%;
    }
  }
  .modal--content__wide {
    width: 900px;
    height: 900px;
  }
  .modal--content__flex-rows-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--padding);
    h3 {
      padding: 0;
      margin: 0;
    }
  }
}
