@import "../sass-vars";

.video-embed {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
}
.videos-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--padding);
  margin-bottom: var(--padding);
  @media (max-width: $breakpoint-mobile) {
    grid-template-columns: repeat(1, 1fr);
    gap: calc(var(--padding) * 2);
  }
  .videos-container--thumb {
    padding: var(--padding);
    font-size: 1.25rem;
    display: flex;
    flex-direction: column;
    gap: var(--padding);
    justify-content: flex-start;
    @media (max-width: $breakpoint-tablet) {
      padding: 0;
    }
  }
  .videos-container--label {
    white-space: normal;
  }
}
.videoBigPlayer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 999999999999;
  animation: fade-in 0.6s forwards;
  .video-embed {
    width: calc(100% - var(--padding) - var(--padding));
    height: auto;
    aspect-ratio: 16 / 9;
    max-height: 80vh;
    max-width: 100vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
  .video-close {
    position: absolute;
    z-index: 9999999;
    top: var(--padding);
    right: var(--padding);
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    cursor: pointer;
    background: transparent;
    border: 0;
    padding: 1rem;
    transition: all 0.2s ease-out;
    .svg * {
      fill: #fff;
    }
  }
  .video-close:hover {
    background-color: var(--colour-primary-faded-more);
  }
}
.video-audit-container {
  display: flex;
  flex-direction: column;
  gap: var(--padding);
  .video-audit-article-select {
    display: flex;
    gap: var(--padding);
    align-items: center;
    justify-content: stretch;
    label {
      flex-shrink: 1;
    }
    .video-audit-article-select--select {
      flex-grow: 1;
    }
  }
  .video-audit-table {
    th:last-child,
    th:nth-child(2) {
      width: 20%;
    }
  }
}
