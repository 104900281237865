.user-file-library {
  background-color: var(--colour-content-background);
  color: var(--colour-content-text);
  padding: var(--padding);
  border: 1px solid var(--colour-borders);
  border-radius: var(--border-radius-outer);
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  gap: var(--padding);
  position: relative;
  h1 {
    padding: 0;
    margin: 0;
    border: 0;
  }
  h1::before {
    display: none;
  }
  .user-file-library-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 0.5rem;
    .user-file-library-toolbar--path {
      flex-grow: 1;
      // border: 1px solid var(--colour-borders);
      border-radius: var(--border-radius-small);
      padding: 0 0.5rem;
      margin-right: var(--padding);
      font-weight: bold;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      justify-content: flex-start;
    }
  }
  .user-file-library-files {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(7rem, 1fr));
    gap: 1rem;
    .user-file-library-files--item {
      cursor: default;
      width: 100%;
      min-width: 0;
      display: flex;
      flex-direction: column;
      row-gap: 0.25rem;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      filter: brightness(1);
      white-space: normal;
      button.user-file-library-files--item-icon {
        width: 100%;
        min-width: 0;
        > * {
          cursor: default;
        }
      }
      .user-file-library-files--item-icon--svg {
        width: 100%;
        aspect-ratio: 9 / 12;
        padding: 0.5rem;
        transition: all 0.2s ease-out;
      }
      .user-file-library-files--item-icon--folder {
        width: 100%;
        position: relative;
        padding-top: 0;
        padding-bottom: 0;

        .user-file-library-files--item-icon > svg {
          padding: 0;
        }
        .user-file-library-files--item-icon-overlay {
          position: absolute;
          width: 100%;
          left: 50%;
          top: 50%;
          transform: translate3d(-50%, -50%, 0);
          cursor: pointer;
          justify-content: center;
          align-items: center;
          display: flex;
          svg {
            width: 20%;
            aspect-ratio: 9 / 9;
            height: auto;
            max-width: 2.5rem;
            padding: 0.5rem;
            transition: all 0.2s ease-out;
          }
        }
      }
      .user-file-library-files--item-icon {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        svg {
          width: 50%;
          aspect-ratio: 1 / 1;
          height: auto;
          max-width: 5rem;
          padding: 0.5rem;
          transition: all 0.2s ease-out;
        }
      }

      .user-file-library-files--item--caption {
        width: 100%;
        text-align: center;
        .editable-text {
          width: 100%;
        }
      }
    }

    .user-file-library-files--item:hover {
      .edit-icons--hoverable {
        opacity: 1;
      }
    }

    .user-file-library-files--item[data-is-drop-active="true"] {
      outline: 2px dashed var(--colour-accepted);
      background-color: var(--colour-accepted-faded);
      * {
        opacity: 0.9;
      }
    }
  }
}
.user-file-library__busy {
  opacity: 0.5;
  pointer-events: none;
}
.user-file-library + h2 {
  margin-top: var(--padding);
}
.article-edit-canvas .user-file-library {
  border: 0;
}

.user-file-library-message {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  color: var(--colour-content-text);
  .user-file-library-file-message-button {
    cursor: pointer;
    border: 2px dashed var(--colour-borders);
    padding: var(--padding);
    width: 50%;
    min-width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    font: inherit;
    background: transparent;
    font-size: 1.2rem;
    &:hover {
      border-color: var(--colour-accepted);
    }
  }
}
