.tippy-box[data-animation="fade"][data-state="hidden"] {
  opacity: 0;
}

[data-tippy-root] {
  max-width: calc(100vw - 10px);
}

.tippy-box {
  color: #fff;
  white-space: normal;
  background-color: #333;
  border-radius: 4px;
  outline: 0;
  font-size: 14px;
  line-height: 1.4;
  transition-property: transform, visibility, opacity;
  position: relative;
}

.tippy-box[data-placement^="top"] > .tippy-arrow {
  bottom: 0;
}

.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: top;
  bottom: -7px;
  left: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow {
  top: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: bottom;
  top: -7px;
  left: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow {
  right: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  transform-origin: 0;
  right: -7px;
}

.tippy-box[data-placement^="right"] > .tippy-arrow {
  left: 0;
}

.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: 100%;
  left: -7px;
}

.tippy-box[data-inertia][data-state="visible"] {
  transition-timing-function: cubic-bezier(.54, 1.5, .38, 1.11);
}

.tippy-arrow {
  color: #333;
  width: 16px;
  height: 16px;
}

.tippy-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.tippy-content {
  z-index: 1;
  padding: 5px 9px;
  position: relative;
}

.dev-info {
  color: #fff;
  z-index: 2147483647;
  cursor: pointer;
  background-color: #000;
  outline: 2px solid #fff;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
  max-width: 400px;
  height: min-content;
  padding: 1rem;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
}

.dev-info .dev-info--item {
  justify-content: flex-start;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.dev-info .dev-info--item .dev-info--item--title {
  font-size: 1.2rem;
  font-weight: 700;
}

.dev-info .dev-info--item:first-child {
  color: #ff0;
}

.dev-info .dev-info--item:nth-child(2) {
  color: #5eff5e;
}

.dev-info .dev-info--item:nth-child(3) {
  color: #ff9696;
}

.dev-info .dev-info--item:nth-child(4) {
  color: #afe2ff;
}

.dev-info[data-show="false"] {
  background: none;
  outline: 0;
  overflow: hidden;
}

.dev-info[data-show="false"] img {
  background: #fff;
  border-radius: 50%;
  width: 3rem;
  height: auto;
  padding: 2px;
}

.reset-password-form {
  justify-content: space-between;
  gap: var(--padding);
  width: 100%;
  display: flex;
}

.reset-password-form input {
  flex-grow: 1;
}

@media (max-width: 500px) {
  .reset-password-form {
    flex-direction: column;
    justify-content: center;
  }
}

.inbox-messages {
  gap: var(--padding);
  flex-direction: column;
  display: flex;
}

.inbox-messages .inbox-message {
  cursor: default;
  padding: var(--padding);
  border: 1px solid var(--colour-borders);
  border-radius: var(--border-radius-inner);
  background-color: var(--colour-content-background);
  color: var(--colour-content-text);
  flex-direction: column;
  gap: .5rem;
  transition: all .2s ease-in-out;
  display: flex;
  position: relative;
}

.inbox-messages .inbox-message .inbox-message-unread {
  top: var(--padding);
  right: var(--padding);
  background-color: var(--colour-buttons);
  color: var(--colour-buttons-text);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1;
  display: flex;
  position: absolute;
}

.inbox-messages .inbox-message .inbox-message-message {
  font-size: .8rem;
}

.inbox-messages .inbox-message .inbox-message-message--delete {
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  padding: 0;
  display: flex;
  position: absolute;
  top: .5rem;
  right: .5rem;
}

.inbox-messages .inbox-message__unread {
  border-width: 2px;
}

.inbox-messages .inbox-message__unread .inbox-message-name {
  font-size: 1.2rem;
  font-weight: bold;
}

.inbox-messages .inbox-message__unread .inbox-message-message {
  font-size: 1rem;
  font-weight: bold;
}

.inbox-messages button.inbox-message:hover {
  cursor: pointer;
  border-color: var(--colour-primary);
}

.messages-conversation {
  gap: var(--padding);
  flex-direction: column;
  display: flex;
}

.messages-conversation .messages-conversation-date {
  border: 1px solid var(--colour-borders);
  border-radius: var(--border-radius-inner);
  box-shadow: var(--shadow);
  align-self: center;
  padding: .5rem;
}

.messages-conversation .messages-conversation-message {
  padding: var(--padding);
  border: 1px solid var(--colour-borders);
  border-radius: var(--border-radius-inner);
  width: calc(100% - var(--padding));
  box-shadow: var(--shadow);
  background-color: var(--colour-content-background);
  color: var(--colour-content-text);
  min-height: 4rem;
  position: relative;
}

.messages-conversation .messages-conversation-message p:nth-child(2) {
  margin-top: 0;
}

.messages-conversation .messages-conversation-message p:empty {
  display: none;
}

.messages-conversation .messages-conversation-message .messages-conversation-time {
  right: var(--padding);
  font-size: .8rem;
  position: absolute;
  bottom: 0;
  transform: translateY(-50%);
}

.messages-conversation .messages-conversation-message:before, .messages-conversation .messages-conversation-message:after {
  content: "";
  top: var(--padding);
  border-style: solid;
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  transform: translateX(-100%);
}

.messages-conversation .messages-conversation-message:before {
  border-color: transparent var(--colour-borders) transparent transparent;
  border-width: 11px;
  left: 0;
}

.messages-conversation .messages-conversation-message:after {
  border-color: transparent var(--colour-content-background) transparent transparent;
  border-width: 10px;
  margin-top: 1px;
  left: 1px;
}

.messages-conversation .messages-conversation-message__me {
  background-color: var(--colour-buttons);
  color: var(--colour-buttons-text);
  border: 0;
  align-self: flex-end;
}

.messages-conversation .messages-conversation-message__me:before {
  border-color: transparent transparent transparent var(--colour-buttons);
  left: auto;
  right: 0;
  transform: translateX(100%);
}

.messages-conversation .messages-conversation-message__me:after {
  display: none;
}

.messages-conversation__admin .messages-conversation-message {
  width: 100%;
}

.messages-conversation__admin .messages-conversation-message:before, .messages-conversation__admin .messages-conversation-message:after {
  display: none;
}

.messages-reply {
  padding-top: var(--padding);
  flex-direction: column;
  align-items: flex-end;
  gap: .5rem;
  width: 100%;
  display: flex;
}

.messages-reply textarea {
  color: var(--colour-content-text);
  background-color: var(--colour-content-background);
  border: 2px solid var(--colour-borders);
  border-radius: var(--border-radius-inner);
  padding: var(--padding);
  resize: vertical;
  font: inherit;
  width: 100%;
  min-height: 8rem;
}

.messages-reply textarea:focus {
  outline: .2rem solid var(--colour-primary-faded);
  border-color: #0000;
}

.drawer--content__submitting .messages-reply {
  opacity: .5;
}

.message-users-form {
  align-items: flex-end;
  gap: var(--padding);
  flex-direction: column;
  width: 100%;
  display: flex;
}

.message-users-form textarea {
  width: 100%;
  height: 10rem;
}

.tags {
  cursor: pointer;
  z-index: 1;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: .125rem;
  max-width: 50%;
  display: inline-flex;
}

.tags .tags--tag {
  white-space: nowrap;
  background-color: var(--colour-content-background);
  color: var(--colour-content-text);
  border: 1px solid var(--colour-borders);
  border-radius: var(--border-radius-small);
  font: inherit;
  flex-shrink: 1;
  justify-content: flex-start;
  align-items: center;
  column-gap: .25rem;
  padding: .25rem;
  font-size: .8rem;
  transition: all .2s ease-out;
  display: flex;
}

.tags .tags--tag button {
  font: inherit;
  color: inherit;
  background: none;
  border: 0;
  align-items: center;
}

.tags .tags--tag .tags--tag-button {
  flex-grow: 1;
  gap: .25rem;
  padding: 0 0 0 .25rem;
  display: flex;
}

.tags .tags--tag .tags--tag-delete {
  cursor: pointer;
  border-radius: 2px;
  flex-shrink: 1;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  padding: 0;
  transition: all .2s ease-out;
  display: flex;
}

.tags .tags--tag .tags--tag-delete:hover {
  background-color: var(--colour-rejected);
  color: #fff;
}

.tags .tags--tag-selection {
  cursor: default;
  background-color: var(--colour-content-background);
  border: 1px solid var(--colour-borders);
  border-radius: var(--border-radius-small);
  transform-origin: 0 0;
  animation: dropdown-overshoot .4s var(--easing) forwards;
  box-shadow: var(--shadow);
  flex-direction: column;
  align-items: stretch;
  row-gap: .125rem;
  width: min-content;
  min-height: 5rem;
  padding: .5rem;
  transition: opacity .2s;
  display: flex;
  top: calc(100% + .25rem);
  transform: scaleY(1);
}

.tags .tags--tag-selection .tags--tag {
  border-radius: var(--border-radius-small);
  background-color: var(--colour-primary-against-white-text);
  color: var(--colour-primary-against-white);
  z-index: 1;
  transition: none;
}

.tags .tags--tag-selection .tags--tag__selected {
  outline: 2px solid var(--colour-primary-against-white);
  z-index: 2;
}

.tags .tags--tag-selection .tags--tag:hover {
  background-color: var(--colour-primary-against-white);
  color: var(--colour-primary-against-white-text);
}

.tags .tags--tag-selection .tags--tag-input {
  background-color: var(--colour-content-background);
  border: 1px solid var(--colour-borders);
  margin: .25rem 0;
  padding: .25rem;
  font-size: .8rem;
}

.tags .tags--tag-selection__busy {
  pointer-events: none;
}

.tags .tags--tag-selection__busy * {
  opacity: .6;
}

.tags .tags--tag-selection__busy .tags--tag-input {
  text-align: center;
}

.tags .tags--tag-selection:focus {
  outline: none;
}

.tags__no-click {
  cursor: default;
}

.tags.pages-tags {
  margin-left: .5rem;
  position: relative;
  top: .1rem;
}

.tags.pages-tags .tags--tag-selection {
  z-index: 10;
  position: absolute;
}

.tags:hover > .tags--tag {
  filter: brightness(1.2);
}

.tags__active {
  z-index: 2;
}

.tags__passive {
  cursor: default;
}

.tags.tags__passive:hover > .tags--tag {
  filter: none;
}

html.theme-dark .tags > .tags--tag {
  background-color: var(--colour-primary-against-black);
  color: var(--colour-primary-against-black-text);
}

.tag {
  border-radius: var(--border-radius-small);
  background-color: var(--colour-primary-against-white-text);
  color: var(--colour-primary-against-white);
  cursor: default;
  align-items: center;
  gap: .25rem;
  margin: 0 .25rem;
  padding: .25rem;
  font-size: .8rem;
  display: inline-flex;
}

.tag.tag__alert {
  background-color: var(--colour-rejected);
}

.absences-list {
  gap: var(--padding);
  flex-direction: column;
  display: flex;
}

.absences-list .absences-list--absence {
  align-items: center;
  column-gap: var(--padding);
  color: var(--colour-content-text);
  background-color: var(--colour-absence-holiday);
  border-radius: var(--border-radius-inner);
  box-shadow: var(--shadow);
  grid-template-columns: 6rem auto;
  grid-template-areas: "elapsed date"
                       "elapsed description";
  padding: .5rem;
  display: grid;
}

.absences-list .absences-list--absence .absences-list--absence--elapsed {
  grid-area: elapsed;
}

.absences-list .absences-list--absence .absences-list--absence--elapsed .absence-elapsed-circle {
  aspect-ratio: 1;
  text-align: center;
  cursor: default;
  background-color: #ffffff40;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.1;
  display: flex;
}

.absences-list .absences-list--absence .absences-list--absence--elapsed .absence-elapsed-circle > div:last-child {
  font-size: 1rem;
  font-weight: normal;
}

.absences-list .absences-list--absence .absences-list--absence--date {
  grid-area: date;
  font-size: 1.5rem;
}

.absences-list .absences-list--absence .absences-list--absence--description {
  grid-area: description;
}

.absences-list .absences-list--absence[data-type="sickness"] {
  background-color: var(--colour-absence-sickness);
}

.absences-list .absences-list--absence[data-type="other"] {
  background-color: var(--colour-absence-other);
}

.absences-list .absences-list--absence[data-type="family"] {
  background-color: var(--colour-absence-family);
}

.employee-absences {
  gap: var(--padding);
  margin-bottom: calc(var(--padding) * 2);
  justify-content: space-evenly;
  align-items: flex-start;
  display: flex;
}

@media (max-width: 1000px) {
  .employee-absences {
    justify-content: center;
    align-items: center;
    gap: calc(var(--padding)  + 1rem);
    flex-direction: column;
  }
}

.employee-absences h3 {
  margin: 0;
  padding: 0;
}

.employee-absences .employee-absences--summary {
  gap: var(--padding);
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 40rem;
  display: flex;
}

.employee-absences .employee-absences--summary .employee-absences--summary-table {
  border: 1px solid var(--colour-borders);
  color: var(--colour-content-text);
  background-color: var(--colour-borders);
  border-collapse: collapse;
  border-radius: var(--border-radius-inner);
  box-shadow: var(--shadow);
  flex-grow: 1;
  grid-template-columns: repeat(2, 1fr);
  gap: 1px;
  width: 100%;
  max-width: 40rem;
  display: grid;
  overflow: hidden;
}

.employee-absences .employee-absences--summary .employee-absences--summary-table > * {
  padding: var(--padding-tight);
  background-color: var(--colour-content-background);
}

.employee-absences .employee-absences--summary .employee-absences--summary-table .employee-absences--summary--label {
  font-weight: bold;
}

.employee-absences .employee-absences--values {
  justify-content: space-between;
  align-items: center;
  gap: var(--padding);
  flex-direction: column;
  flex-grow: 1;
  max-width: 30rem;
  display: flex;
}

.employee-absences .employee-absences--values h3 {
  text-transform: capitalize;
}

.employee-absences .employee-absences--values .holiday-summary--allocation {
  padding: 0 var(--padding);
  grid-template-columns: repeat(4, 1fr);
}

.employee-absences .employee-absences--values .holiday-summary--allocation .holiday-summary--allocation-label {
  display: none;
}

.employee-absences .employee-absences--values .holiday-summary--allocation .holiday-summary--allocation-item:nth-child(6) {
  grid-area: 2 / 2 / auto / 4;
  padding-top: 1rem;
}

.employee-absences .employee-absences--values .holiday-summary--allocation .holiday-summary--allocation-remaining-label {
  text-align: center;
  grid-area: 3 / 2 / auto / 4;
}

.remaining-leave {
  background-color: var(--colour-absence-holiday);
  padding: var(--padding-tight);
  border-radius: var(--border-radius-inner);
  text-align: center;
  min-height: calc(2ch + var(--padding-tight)  + var(--padding-tight));
  justify-content: center;
  align-items: center;
  display: flex;
}

.remaining-leave .lds-ellipsis div {
  filter: saturate(0);
}

@media print {
  #print-container .absences-list-container .absences-list {
    columns: 3;
    background: none;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0;
    padding: 0;
    font-size: .8rem;
    display: block;
  }

  #print-container .absences-list-container .absences-list .absences-list--absence {
    box-shadow: none;
    break-inside: avoid;
    background: none;
    grid-template-columns: 5rem auto;
    row-gap: .1cm;
  }

  #print-container .absences-list-container .absences-list .absences-list--absence .absence-elapsed-circle {
    border: 2mm solid var(--colour-absence-holiday);
    background: none;
    font-size: 1.2rem;
  }

  #print-container .absences-list-container .absences-list .absences-list--absence .absences-list--absence[data-type="sickness"] .absence-elapsed-circle {
    border-color: var(--colour-absence-sickness);
  }

  #print-container .absences-list-container .absences-list .absences-list--absence .absences-list--absence[data-type="other"] .absence-elapsed-circle {
    border-color: var(--colour-absence-other);
  }

  #print-container .absences-list-container .absences-list .absences-list--absence .absences-list--absence[data-type="family"] .absence-elapsed-circle {
    border-color: var(--colour-absence-family);
  }

  #print-container .absences-list-container .absences-list .absences-list--absence .absences-list--absence--date {
    align-self: flex-end;
    font-size: 1.1rem;
  }

  #print-container .absences-list-container .absences-list .absences-list--absence .absences-list--absence--description {
    align-self: flex-start;
  }
}



.drawer-overlay {
  content: "";
  z-index: 51;
  background-color: #000000b3;
  width: 100vw;
  height: 100vh;
  transition: background-color .5s ease-out;
  animation: .5s forwards fade-in;
  position: fixed;
  top: 0;
  left: 0;
}

.drawer-overlay__closed {
  background-color: #0000;
}

.drawer-container {
  animation: drawer-slide-in .2s var(--easing) forwards;
  z-index: 52;
  width: 100%;
  max-width: 60rem;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
}

.drawer {
  background-color: var(--colour-primary-faded);
  color: var(--colour-content-text);
  transition: all .3s var(--easing);
  grid-template: "heading close" min-content
                 "content content"
                 "footer footer" min-content
                 / 99fr 1fr;
  align-items: center;
  width: 100%;
  height: 100vh;
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(0);
}

.drawer h3 {
  margin: 0;
}

.drawer .drawer--heading {
  padding: var(--padding);
  color: var(--colour-primary-faded-text);
  grid-area: heading;
  margin: 0;
}

.drawer .drawer--heading * {
  color: var(--colour-primary-faded-text);
  margin: 0;
}

.drawer .drawer--heading h2 {
  font-size: 2rem;
}

.drawer .drawer--close {
  padding: var(--padding);
  gap: var(--padding);
  grid-area: close;
  width: min-content;
  display: flex;
}

.drawer .drawer--close .button__arrow-back {
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.drawer .drawer--close .button__arrow-back > * {
  min-width: auto;
}

.drawer .drawer--content {
  background-color: var(--colour-background);
  padding-left: var(--padding);
  padding-right: var(--padding);
  padding-top: var(--padding);
  padding-bottom: var(--padding);
  scroll-behavior: smooth;
  grid-area: content;
  height: 100%;
  position: relative;
  overflow: auto;
}

.drawer .drawer--content:before {
  content: "";
  z-index: 100;
  margin-top: calc(0px - var(--padding));
  background-image: linear-gradient(#0000000d 0%, #0000 100%);
  width: 100%;
  height: .8rem;
  position: fixed;
  left: 0;
}

.drawer .drawer--content__submitting {
  pointer-events: none;
}

.drawer .drawer--content__submitting > * {
  opacity: .5;
}

.drawer .drawer-action-buttons {
  background-color: var(--colour-content-background);
  color: var(--colour-content-text);
  padding: .5rem var(--padding);
  border: 1px solid var(--colour-borders);
  border-radius: var(--border-radius-outer);
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
  display: flex;
  overflow: visible;
}

.drawer .drawer-action-buttons .drawer-action-buttons-heading {
  text-align: center;
  flex-grow: 1;
  font-weight: normal;
}

.drawer .drawer-action-buttons .drawer-action-buttons-heading b {
  text-align: center;
  display: block;
}

.drawer .drawer-action-buttons[data-is-disabled="true"] {
  pointer-events: none;
  opacity: .8;
}

.drawer .drawer--footer-buttons {
  padding: var(--padding);
  background-color: var(--colour-content-background);
  grid-area: footer;
  justify-content: space-evenly;
  align-items: stretch;
  height: min-content;
  display: flex;
  position: relative;
}

.drawer .drawer--footer-buttons .button {
  z-index: 1;
  justify-content: center;
  min-width: 10rem;
  font-size: 1.1rem;
}

.drawer .drawer--footer-buttons:before {
  content: "";
  background-color: var(--colour-primary-faded-more);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.drawer .drawer-form--section {
  padding: var(--padding);
}

.drawer .richtext-container {
  border: 1px solid var(--colour-borders);
  border-radius: var(--border-radius-small);
  padding: var(--padding-tight);
}

.drawer__closed {
  transform: translateX(100%) !important;
}

@keyframes drawer-slide-in {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

.file-library-links {
  justify-content: space-between;
  align-items: stretch;
  gap: var(--padding);
  z-index: 20;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  display: grid;
}

.file-library-links button {
  border: 2px solid var(--colour-borders);
  border-radius: var(--border-radius-inner);
  cursor: pointer;
  background-color: var(--colour-content-background);
  color: var(--colour-content-text);
  font: inherit;
  flex-grow: 1;
  flex-basis: 0;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  transition: border .2s ease-out;
  display: flex;
}

.file-library-links button svg {
  height: 4rem;
}

.file-library-links button div {
  text-align: center;
  flex-grow: 1;
}

.file-library-links button:hover {
  filter: none;
  border: 2px solid var(--colour-buttons);
}

.file-library-links button[data-can-download="false"] {
  cursor: default;
}

.file-library-links button[data-can-download="false"]:hover {
  filter: none;
}

.video-embed {
  aspect-ratio: 16 / 9;
  width: 100%;
  height: auto;
}

.videos-container {
  gap: var(--padding);
  margin-bottom: var(--padding);
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

@media (max-width: 700px) {
  .videos-container {
    gap: calc(var(--padding) * 2);
    grid-template-columns: repeat(1, 1fr);
  }
}

.videos-container .videos-container--thumb {
  padding: var(--padding);
  gap: var(--padding);
  flex-direction: column;
  justify-content: flex-start;
  font-size: 1.25rem;
  display: flex;
}

@media (max-width: 1000px) {
  .videos-container .videos-container--thumb {
    padding: 0;
  }
}

.videos-container .videos-container--label {
  white-space: normal;
}

.videoBigPlayer {
  z-index: 2147483647;
  background: #000000e6;
  width: 100vw;
  height: 100vh;
  animation: .6s forwards fade-in;
  position: fixed;
  top: 0;
  left: 0;
}

.videoBigPlayer .video-embed {
  width: calc(100% - var(--padding)  - var(--padding));
  aspect-ratio: 16 / 9;
  max-width: 100vw;
  height: auto;
  max-height: 80vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.videoBigPlayer .video-close {
  z-index: 9999999;
  top: var(--padding);
  right: var(--padding);
  cursor: pointer;
  background: none;
  border: 0;
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
  padding: 1rem;
  transition: all .2s ease-out;
  position: absolute;
}

.videoBigPlayer .video-close .svg * {
  fill: #fff;
}

.videoBigPlayer .video-close:hover {
  background-color: var(--colour-primary-faded-more);
}

.video-audit-container {
  gap: var(--padding);
  flex-direction: column;
  display: flex;
}

.video-audit-container .video-audit-article-select {
  gap: var(--padding);
  justify-content: stretch;
  align-items: center;
  display: flex;
}

.video-audit-container .video-audit-article-select label {
  flex-shrink: 1;
}

.video-audit-container .video-audit-article-select .video-audit-article-select--select {
  flex-grow: 1;
}

.video-audit-container .video-audit-table th:last-child, .video-audit-container .video-audit-table th:nth-child(2) {
  width: 20%;
}

.user-file-library {
  background-color: var(--colour-content-background);
  color: var(--colour-content-text);
  padding: var(--padding);
  border: 1px solid var(--colour-borders);
  border-radius: var(--border-radius-outer);
  gap: var(--padding);
  flex-direction: column;
  min-height: 20vh;
  display: flex;
  position: relative;
}

.user-file-library h1 {
  border: 0;
  margin: 0;
  padding: 0;
}

.user-file-library h1:before {
  display: none;
}

.user-file-library .user-file-library-toolbar {
  justify-content: space-between;
  align-items: stretch;
  gap: .5rem;
  display: flex;
}

.user-file-library .user-file-library-toolbar .user-file-library-toolbar--path {
  border-radius: var(--border-radius-small);
  margin-right: var(--padding);
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;
  gap: .5rem;
  padding: 0 .5rem;
  font-weight: bold;
  display: flex;
}

.user-file-library .user-file-library-files {
  grid-template-columns: repeat(auto-fill, minmax(7rem, 1fr));
  gap: 1rem;
  display: grid;
}

.user-file-library .user-file-library-files .user-file-library-files--item {
  cursor: default;
  filter: brightness();
  white-space: normal;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: .25rem;
  width: 100%;
  min-width: 0;
  display: flex;
  position: relative;
}

.user-file-library .user-file-library-files .user-file-library-files--item button.user-file-library-files--item-icon {
  width: 100%;
  min-width: 0;
}

.user-file-library .user-file-library-files .user-file-library-files--item button.user-file-library-files--item-icon > * {
  cursor: default;
}

.user-file-library .user-file-library-files .user-file-library-files--item .user-file-library-files--item-icon--svg {
  aspect-ratio: 9 / 12;
  width: 100%;
  padding: .5rem;
  transition: all .2s ease-out;
}

.user-file-library .user-file-library-files .user-file-library-files--item .user-file-library-files--item-icon--folder {
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
}

.user-file-library .user-file-library-files .user-file-library-files--item .user-file-library-files--item-icon--folder .user-file-library-files--item-icon > svg {
  padding: 0;
}

.user-file-library .user-file-library-files .user-file-library-files--item .user-file-library-files--item-icon--folder .user-file-library-files--item-icon-overlay {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.user-file-library .user-file-library-files .user-file-library-files--item .user-file-library-files--item-icon--folder .user-file-library-files--item-icon-overlay svg {
  aspect-ratio: 9 / 9;
  width: 20%;
  max-width: 2.5rem;
  height: auto;
  padding: .5rem;
  transition: all .2s ease-out;
}

.user-file-library .user-file-library-files .user-file-library-files--item .user-file-library-files--item-icon {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.user-file-library .user-file-library-files .user-file-library-files--item .user-file-library-files--item-icon svg {
  aspect-ratio: 1;
  width: 50%;
  max-width: 5rem;
  height: auto;
  padding: .5rem;
  transition: all .2s ease-out;
}

.user-file-library .user-file-library-files .user-file-library-files--item .user-file-library-files--item--caption {
  text-align: center;
  width: 100%;
}

.user-file-library .user-file-library-files .user-file-library-files--item .user-file-library-files--item--caption .editable-text {
  width: 100%;
}

.user-file-library .user-file-library-files .user-file-library-files--item:hover .edit-icons--hoverable {
  opacity: 1;
}

.user-file-library .user-file-library-files .user-file-library-files--item[data-is-drop-active="true"] {
  outline: 2px dashed var(--colour-accepted);
  background-color: var(--colour-accepted-faded);
}

.user-file-library .user-file-library-files .user-file-library-files--item[data-is-drop-active="true"] * {
  opacity: .9;
}

.user-file-library__busy {
  opacity: .5;
  pointer-events: none;
}

.user-file-library + h2 {
  margin-top: var(--padding);
}

.article-edit-canvas .user-file-library {
  border: 0;
}

.user-file-library-message {
  color: var(--colour-content-text);
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.user-file-library-message .user-file-library-file-message-button {
  cursor: pointer;
  border: 2px dashed var(--colour-borders);
  padding: var(--padding);
  font: inherit;
  background: none;
  justify-content: center;
  align-items: center;
  width: 50%;
  min-width: 200px;
  font-size: 1.2rem;
  display: flex;
}

.user-file-library-message .user-file-library-file-message-button:hover {
  border-color: var(--colour-accepted);
}

.modal {
  z-index: 1000;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  animation: .3s forwards fade-in;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.modal .modal--overlay {
  background-color: #000c;
  width: 100%;
  height: 100%;
  position: fixed;
}

.modal .close-button {
  z-index: 3;
  top: var(--padding);
  right: var(--padding);
  position: fixed;
}

.modal .print-button {
  z-index: 3;
  top: var(--padding);
  right: calc(var(--padding)  + 60px);
  position: fixed;
}

.modal .modal--content {
  z-index: 2;
  background-color: var(--colour-content-background);
  border: 1px solid var(--colour-borders);
  padding: calc(var(--padding) * 2);
  border-radius: var(--border-radius-outer);
  gap: var(--padding);
  flex-direction: column;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 500px;
  max-width: min(900px, 95vw);
  min-height: 300px;
  max-height: 90vh;
  transition: width 1s ease-in-out;
  display: flex;
  position: relative;
  overflow: auto;
}

.modal .modal--content > h2, .modal .modal--content h3 {
  margin: 0;
  padding: 0;
}

.modal .modal--content .modal--close {
  cursor: pointer;
  color: #000;
  font-size: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.modal .modal--content .close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

@media (max-width: 500px) {
  .modal .modal--content {
    width: 95%;
    min-width: 0;
  }
}

.modal .modal--content__wide {
  width: 900px;
  height: 900px;
}

.modal .modal--content__flex-rows-center {
  justify-content: center;
  align-items: center;
  gap: var(--padding);
  flex-direction: column;
  display: flex;
}

.modal .modal--content__flex-rows-center h3 {
  margin: 0;
  padding: 0;
}

.token-login-container {
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
  overflow: hidden;
}

/*# sourceMappingURL=index.7c031824.css.map */
