.inbox-messages {
  display: flex;
  flex-direction: column;
  gap: var(--padding);
  .inbox-message {
    cursor: default;
    padding: var(--padding);
    border: 1px solid var(--colour-borders);
    border-radius: var(--border-radius-inner);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    transition: all 0.2s ease-in-out;
    background-color: var(--colour-content-background);
    color: var(--colour-content-text);
    .inbox-message-unread {
      position: absolute;
      top: var(--padding);
      right: var(--padding);
      width: 1.5rem;
      height: 1.5rem;
      line-height: 1;
      border-radius: 50%;
      background-color: var(--colour-buttons);
      color: var(--colour-buttons-text);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .inbox-message-message {
      font-size: 0.8rem;
    }
    .inbox-message-message--delete {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      width: 20px;
      height: 20px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .inbox-message__unread {
    border-width: 2px;
    .inbox-message-name {
      font-weight: bold;
      font-size: 1.2rem;
    }
    .inbox-message-message {
      font-weight: bold;
      font-size: 1rem;
    }
  }
  button.inbox-message:hover {
    cursor: pointer;
    border-color: var(--colour-primary);
  }
}
.messages-conversation {
  display: flex;
  flex-direction: column;
  gap: var(--padding);
  .messages-conversation-date {
    align-self: center;
    padding: 0.5rem;
    border: 1px solid var(--colour-borders);
    border-radius: var(--border-radius-inner);
    box-shadow: var(--shadow);
  }
  .messages-conversation-message {
    padding: var(--padding);
    border: 1px solid var(--colour-borders);
    border-radius: var(--border-radius-inner);
    position: relative;
    width: calc(100% - var(--padding));
    min-height: 4rem;
    box-shadow: var(--shadow);
    background-color: var(--colour-content-background);
    color: var(--colour-content-text);
    p:nth-child(2) {
      margin-top: 0;
    }
    p:empty {
      display: none;
    }
    .messages-conversation-time {
      position: absolute;
      transform: translateY(-50%);
      right: var(--padding);
      bottom: 0;
      font-size: 0.8rem;
    }
  }
  .messages-conversation-message::before,
  .messages-conversation-message::after {
    content: "";
    display: block;
    position: absolute;
    top: var(--padding);
    width: 0;
    height: 0;
    border-style: solid;
    transform: translateX(-100%);
  }
  .messages-conversation-message::before {
    border-color: transparent var(--colour-borders) transparent transparent;
    border-width: 11px;
    left: 0;
  }
  .messages-conversation-message::after {
    border-color: transparent var(--colour-content-background) transparent transparent;
    border-width: 10px;
    margin-top: 1px;
    left: 1px;
  }
  .messages-conversation-message__me {
    align-self: flex-end;
    background-color: var(--colour-buttons);
    color: var(--colour-buttons-text);
    border: 0;
  }
  .messages-conversation-message__me::before {
    border-color: transparent transparent transparent var(--colour-buttons);
    left: auto;
    right: 0;
    transform: translateX(100%);
  }
  .messages-conversation-message__me::after {
    display: none;
  }
}
.messages-conversation__admin {
  .messages-conversation-message {
    width: 100%;
  }
  .messages-conversation-message::before,
  .messages-conversation-message::after {
    display: none;
  }
}
.messages-reply {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5rem;
  width: 100%;
  padding-top: var(--padding);
  textarea {
    color: var(--colour-content-text);
    background-color: var(--colour-content-background);
    border: 2px solid var(--colour-borders);
    border-radius: var(--border-radius-inner);
    min-height: 8rem;
    padding: var(--padding);
    resize: vertical;
    font: inherit;
    width: 100%;
  }
  textarea:focus {
    outline: 0.2rem solid var(--colour-primary-faded);
    border-color: transparent;
  }
}
.drawer--content__submitting {
  .messages-reply {
    opacity: 0.5;
  }
}
.message-users-form {
  textarea {
    width: 100%;
    height: 10rem;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--padding);
  width: 100%;
}
